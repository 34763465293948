import {ARHIJoinState} from '../../rootReducer';
import _get from 'lodash/get';
import type {GovernmentDetails, Nullable, PreviousFundDetails, IncomeTier, GovernmentRebateAcknowledgement} from '@nib/types-session-api';

export const selectData = (state: ARHIJoinState): GovernmentDetails => _get(state, 'session.session.governmentDetails') || ({} as GovernmentDetails);
export const getPolicyHolderPreviousFundDetails = (state: ARHIJoinState): PreviousFundDetails => selectData(state).policyHolderPreviousFundDetails || ({} as PreviousFundDetails);
export const getPartnerPreviousFundDetails = (state: ARHIJoinState): PreviousFundDetails => selectData(state).partnerPreviousFundDetails || ({} as PreviousFundDetails);
export const getGovernmentRebateAcknowledgement = (state: ARHIJoinState): GovernmentRebateAcknowledgement =>
  selectData(state).governmentRebateAcknowledgement || ({} as GovernmentRebateAcknowledgement);
export const getAgrDeclaration = (state: ARHIJoinState) => getGovernmentRebateAcknowledgement(state).declarationAllDetailsCorrect;
export const getRebateIsRequested = (state: ARHIJoinState) => getGovernmentRebateAcknowledgement(state).rebateIsRequested;
export const getIncomeTier = (state: ARHIJoinState): IncomeTier | undefined => selectData(state).incomeTier;

export const getPolicyHolderPreviouslyHadHealthInsurance = (state: ARHIJoinState) => getPolicyHolderPreviousFundDetails(state).previouslyHadHealthInsurance;
export const getPolicyHolderPreviousFundCode = (state: ARHIJoinState) => getPolicyHolderPreviousFundDetails(state).code;
export const getPolicyHolderContinuousCover = (state: ARHIJoinState) => getPolicyHolderPreviousFundDetails(state).continuousCover;

export const getPartnerPreviouslyHadHealthInsurance = (state: ARHIJoinState) => getPartnerPreviousFundDetails(state).previouslyHadHealthInsurance;

export const getPartnerContinuousCover = (state: ARHIJoinState) => getPartnerPreviousFundDetails(state).continuousCover;

export default (state: ARHIJoinState): Nullable<PreviousFundDetails> => selectData(state).partnerPreviousFundDetails;

export const getApplyGovernmentRebate = (state: ARHIJoinState) => selectData(state).applyGovernmentRebate;

export const getMedicareExpiry = (state: ARHIJoinState) => selectData(state).medicareExpiry;
export const getMedicareNumber = (state: ARHIJoinState) => selectData(state).medicareNumber;
export const getMedicareCardType = (state: ARHIJoinState) => selectData(state).medicareCardType;
